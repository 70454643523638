<template>
  <section>
    <q-tab-panels v-model="tab" keep-alive animated>
        <q-tab-panel name="modes">
            <div class="column q-py-md">
                <span class="text-h5 q-pa-xs text-grey-6">
                    <q-icon name="payments"/> Select Payment Mode
                </span>
            </div>
            <div>
                <q-list bordered separator>
                    <q-item clickable v-ripple @click="showCardInput">
                        <q-item-section avatar>
                            <img src="/payment-card.png" style="width: 150px">
                        </q-item-section>
                        <q-item-section>
                            <q-item-label class="text-h6">Card Payment</q-item-label>
                            <q-item-label caption>Pay <span class="text-positive">{{ reservationFee }}</span> using Visa/Mastercard.</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click="showGcashScreen">
                        <q-item-section avatar>
                            <img src="/payment-gcash.webp" style="width: 150px">
                        </q-item-section>
                        <q-item-section>
                            <q-item-label class="text-h6">GCash</q-item-label>
                            <q-item-label caption>Pay <span class="text-positive">{{ reservationFee }}</span> using your GCash e-wallet.</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>
            <div class="flex items-center justify-center q-mt-md">
                <q-btn rounded color="secondary"
                    class="q-ma-sm" size="lg" label="Back to Booking" style="width: 250px"
                    @click="close"
                />
            </div>
        </q-tab-panel>
        <q-tab-panel name="authentication">
            <div class="column q-py-md">
                <span class="text-h5 q-pa-xs text-grey-6">
                    <q-icon name="lock"/> Authentication
                </span>
            </div>
            <iframe
                :src="url"
                width="100%"
                height="400"
                frameborder="0"
            >
            </iframe>
        </q-tab-panel>
        <q-tab-panel name="card_input">
            <div class="column q-py-md">
                <span class="text-h5 q-pa-xs text-grey-6">
                    <q-icon name="credit_card"/> Enter Card Details
                </span>
            </div>
            <div>
                <CardInputComponent v-on:submit="pay" v-on:cancel="tab = 'modes'"/>
            </div>
        </q-tab-panel>
    </q-tab-panels>
  </section>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { useStore } from 'vuex';
import CardInputComponent from '../Common/CardInputComponent.vue';
import constants from '@/const'

const store = useStore();
const reservationFee = ref(store.state.data.invoice.reservation_fee);
const invoice = ref(store.state.data.invoice);
const tab = ref('modes');
const url = ref('http://example.com');
const emit = defineEmits(['close']);

window.addEventListener('message', (ev) => {
    if (ev.data === '3DS-authentication-complete') {
        continuePayment();
    }
});

function showCardInput() {
    tab.value = 'card_input';
}

function close() {
    emit('close', true);
}

function showGcashScreen() {
    store.commit('setLoading', {
        show: true,
        title: 'Preparing..',
        subtitle: 'Please wait while we prepare to authenticate your transaction request.'
    });
    store.dispatch('submitGcash').then((response) => {
        if (response.status == 'success') {
            pay(response.paymentMethodId);
        }
    }).catch(() => {
        store.commit('setError', {
            show: true,
            message: 'There has been an error while trying to authenticate. Please try again later.'
        });
    })
}

function continuePayment() {
    store.commit('setLoading', {
        show: true,
        title: 'Processing Transaction..',
        subtitle: 'Do not close this window to avoid transaction errors.'
    });
    store.dispatch('continue', {
        invoice_id: invoice.value.id,
        payment_intent_id: invoice.value.payment_intent_id
    }).then((response) => {
        handleResponse(response);
    }).catch(() => {
        store.commit('setError', {
            show: true,
            message: 'There has been an error while trying to continue to process your payment. Please try again later.'
        });
        tab.value = 'modes';
    })
}

function pay(paymentMethodId) {
    store.commit('setLoading', {
        show: true,
        title: 'Processing Transaction..',
        subtitle: 'Do not close this window to avoid transaction errors.'
    });
    store.dispatch('pay', {
        invoice_id: invoice.value.id,
        paymentIntentId: invoice.value.payment_intent_id,
        paymentMethodId: paymentMethodId
    }).then((response) => {
        handleResponse(response);
    }).catch((err) => {
        if (err.response) {
            if (err.response.status == 400) {
                if (err.response.data.status == 'generic_decline') {
                    store.commit('setError', {
                        show: true,
                        message: err.response.data.data.message
                    });
                } else {
                    store.commit('setError', {
                        show: true,
                        message: 'There was an unexpected error while trying to process your payment. Please refresh the page.'
                    });
                } 
            } else {
                tab.value = 'modes';
                store.commit('setError', {
                    show: true,
                    message: 'An unexpected error has occurred during payment attempt. Please try again.'
                });
            }
        }
    })
}

function handleResponse(response) {
    if (response.data.status == constants.paymentResponses.succeeded) {
        store.commit('setView', 4);
    } else if (response.data.status == constants.paymentResponses.processing) {
        setTimeout(() => {
            store.commit('setLoading', {
                show: true,
                title: 'Servers Busy..',
                subtitle: 'We are still trying to resolve your payment. Please wait..'
            });
            continuePayment();
        }, 5000);
    } else if (response.data.status == constants.paymentResponses.authenticationRequired) {
        url.value = response.data.data.url;
        tab.value = 'authentication';
    } else if (response.data.status == constants.paymentResponses.paid){
        store.commit('setView', 4);
    } else {
        store.commit('setView', 4);
    }
}
</script>