import { createStore } from 'vuex'
import axios from 'axios'
import constants from '../const'

export default createStore({
  state: {
    view: 1,
    data: {
      booking: null,
      invoice: null
    },
    loading: {
      show: false,
      title: '',
      subtitle: ''
    },
    error: {
      show: false,
      message: ''
    }
  },
  mutations: {
    setView(state, value = 1) {
      state.view = value;
    },
    setData(state, data) {
      state.data = data;
    },
    setPaymentIntentId(state, paymentIntentId) {
      if (state.data.invoice != null) {
        state.data.invoice.payment_intent_id = paymentIntentId;
      }
    },
    setLoading(state, payload) {
      state.loading.show = payload.show;
      state.loading.title = payload.title;
      state.loading.subtitle = payload.subtitle;
    },
    setError(state, payload) {
      state.error.show = payload.show;
      state.error.message = payload.message;
    }
  },
  actions: {
    submitCard(_, card) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: 'https://api.paymongo.com/v1/payment_methods',
          data: {
            data: {
              attributes: {
                details: {
                  card_number: card.number,
                  exp_month: Number(card.expMonth),
                  exp_year: Number(card.expYear),
                  cvc: String(card.securityCode)
                },
                type: constants.paymentMethods.card
              }
            }
          }
        }).then((response) => {
          return resolve({
            status: 'card_submitted',
            paymentMethodId: response.data['data']['id']
          });
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    submitGcash() {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: 'https://api.paymongo.com/v1/payment_methods',
          data: {
            data: {
              attributes: {
                type: constants.paymentMethods.gcash
              }
            }
          }
        }).then((response) => {
          return resolve({
            status: 'success',
            paymentMethodId: response.data['data']['id']
          });
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    get({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          baseURL: '', // To prevent axios from adding a forward / that produces 301
          url: process.env.VUE_APP_API_BASE_URL + '?booking_code=' + payload.code + '&surname=' + payload.surname
        }).then((response) => {
          commit('setData', response.data.data);
          return resolve(response.data.data);
        }).catch((err) => {
          return reject(err);
        }).finally(() => {
          commit('setLoading', {
            show: false,
            title: '',
            subtitle: ''
          })
        })
      });
    },
    initialize({commit}, invoiceNumber) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          data: {
            invoice_number: invoiceNumber
          },
          url:  '/initialize',
        }).then((response) => {
          commit('setPaymentIntentId', response.data.data.payment_intent_id);

          return resolve(response.data.data.payment_intent_id);
        }).catch((err) => {
          return reject(err);
        }).finally(() => {
          commit('setLoading', {
            show: false,
            title: '',
            subtitle: ''
          })
        })
      });
    },
    pay({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: '',
          data: {
            invoice_id: payload.invoice_id,
            payment_intent_id: payload.paymentIntentId,
            payment_method_id: payload.paymentMethodId
          }
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        }).finally(() => {
          commit('setLoading', {
            show: false,
            title: '',
            subtitle: ''
          })
        })
      });
    },
    continue({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: 'continue',
          data: {
            invoice_id: payload.invoice_id,
            payment_intent_id: payload.payment_intent_id
          }
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        }).finally(() => {
          commit('setLoading', {
            show: false,
            title: '',
            subtitle: ''
          })
        })
      });
    }
  },
})
